@import "../../../../Common/Styles/main";

.domains-block {
  display: flex;
  flex-direction: column;

  background-color: $color_gray_light;
  padding: 1rem;
  border-radius: 0.6rem;

  &__name {
    font-weight: bold;
  }

  &__domain-name {
    margin-top: 1rem;
  }

  &__image-wrapper {
    position: relative;
    margin-top: 1rem;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;

    &::after {
      content: '';
      position: relative;
      display: block;
      padding-bottom: 100%;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button {
    width: 100%;
    margin-top: 1rem;
  }
}