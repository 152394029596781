@import "App/Common/Styles/main";

:root {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color_gray_light_extra;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
  color: $color_black;
}

button {
  padding-left: 0;
  padding-right: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

input {
  outline: none;
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.icon-path-hover {
  @include transition(fill);
}

.body {
  width: 100%;
  min-height: 100vh;
}

.hidden {
  display: none;
}

.hidden-visibly {
  position: absolute;
  z-index: -10;
  opacity: 0;
  width: 0;
  height: 0;
}