@import "Common/Styles/main";

.main {
  box-sizing: border-box;
  padding: 20px;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  & > * {
    padding: 10px;
  }
}

.page-need-button {
  font-size: 16px;
  color: $color_gray;
  font-weight: bold;
}

.red {
  color: $color_red;
}

.green {
  color: $color_green;
}

.blue {
  color: $color_blue;
}