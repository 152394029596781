@import "../../../Common/Styles/main";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  @include transition(background-color);
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.button.green {
  background-color: $color_green;
  color: $color_white;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: $color_green--hover;
  }
}

.button.red {
  background-color: $color_red;
  color: $color_white;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: $color_red--hover;
  }
}