@import "../../Common/Styles/main";

.page-domains {
  margin-top: 1rem;
  display: grid;
  grid-gap: 2rem;

  @include media_interval($media1024, 0) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include media_interval($media600, $media1024) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media_interval($media450, $media600) {
    grid-template-columns: repeat(2, 1fr);
  }
}