//colors
$color_white: #ffffff;


$color_green_light: #1cc88a;
$color_green: #07923f;
$color_green--hover: #1cc88a;


$color_blue_light_extra: #d1d3e2;

$color_blue_light: #e8ebf5;

$color_blue: #4e73df;
$color_blue--hover: #2e59d9;

$color_blue_dark: #3b5998;
$color_blue_dark--hover: #294698;

$color_yellow: #f6c23e;

$color_gray_dark: #5a5c69;
$color_gray: #858796;
$color_gray_light: #eaecf4;
$color_gray_light_extra: #f8f9fc;

$color_black: #000000;

$color_red: #e74a3b;
$color_red--hover: #c84033;

//transitions
$transition_duration: 0.3s;
$transition_easing: ease-in-out;

//fonts
$font-roboto: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;