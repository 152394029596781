@import "../../Common/Styles/main";

.page-domain {
  padding: 2rem;
  display: grid;
  grid-template-areas:
          "statistic statistic"
          "settings-user sent-push"
          "settings-domain sent-push"
          "mailing-list mailing-list";
  align-items: flex-start;
  grid-gap: 2rem;
}

.domain-statistic {
  grid-area: statistic;
}

.domain-settings-user {
  grid-area: settings-user;
}

.domain-settings-domain {
  grid-area: settings-domain;
}

.domain-sent-push {
  grid-area: sent-push;
}

.domain-mailing-list {
  grid-area: mailing-list;
}