@import "../../../../Common/Styles/main";

.domains-block.domains-block--add {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: bold;
  text-transform: uppercase;
  color: $color_gray;
  @include transition(background-color);

  &:hover,
  &:focus {
    background-color: $color_gray_light-extra;
  }
}