:root{font-size:16px}body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#f8f9fc}button{font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}p,h1,h2,h3,h4,h5,h6{margin:0}a{text-decoration:none;outline:none;color:#000}button{padding-left:0;padding-right:0;outline:none;cursor:pointer;background-color:transparent;border:none}input{outline:none;margin:0}ul{list-style:none;padding-left:0;margin:0}.icon-path-hover{transition:fill .3s ease-in-out}.body{width:100%;min-height:100vh}.hidden{display:none}.hidden-visibly{position:absolute;z-index:-10;opacity:0;width:0;height:0}

.main{box-sizing:border-box;padding:20px;min-height:100vh}.header{display:flex;flex-wrap:wrap;margin:-10px}.header>*{padding:10px}.page-need-button{font-size:16px;color:#858796;font-weight:bold}.red{color:#e74a3b}.green{color:#07923f}.blue{color:#4e73df}

.page-login{display:flex;align-items:center;justify-content:center;min-height:100vh}

.page-domains{margin-top:1rem;display:grid;grid-gap:2rem}@media (min-width: 1024px){.page-domains{grid-template-columns:repeat(5, 1fr)}}@media (min-width: 600px) and (max-width: 1023px){.page-domains{grid-template-columns:repeat(3, 1fr)}}@media (min-width: 450px) and (max-width: 599px){.page-domains{grid-template-columns:repeat(2, 1fr)}}

.domains-block{display:flex;flex-direction:column;background-color:#eaecf4;padding:1rem;border-radius:0.6rem}.domains-block__name{font-weight:bold}.domains-block__domain-name{margin-top:1rem}.domains-block__image-wrapper{position:relative;margin-top:1rem;width:100%;border-radius:50%;overflow:hidden}.domains-block__image-wrapper::after{content:'';position:relative;display:block;padding-bottom:100%}.domains-block__image{position:absolute;width:100%;height:100%;object-fit:cover}.domains-block__button{width:100%;margin-top:1rem}

.button{display:inline-flex;justify-content:center;align-items:center;box-sizing:border-box;padding:0.5rem;border:none;outline:none;border-radius:0.5rem;transition:background-color .3s ease-in-out;cursor:pointer;font-weight:bold;text-decoration:none}.button.green{background-color:#07923f;color:#fff}.button.green:hover:not([disabled]),.button.green:focus:not([disabled]){background-color:#1cc88a}.button.red{background-color:#e74a3b;color:#fff}.button.red:hover:not([disabled]),.button.red:focus:not([disabled]){background-color:#c84033}

.domains-block.domains-block--add{display:flex;height:100%;justify-content:center;align-items:center;box-sizing:border-box;font-weight:bold;text-transform:uppercase;color:#858796;transition:background-color .3s ease-in-out}.domains-block.domains-block--add:hover,.domains-block.domains-block--add:focus{background-color:#f8f9fc}

.page-domain{padding:2rem;display:grid;grid-template-areas:"statistic statistic" "settings-user sent-push" "settings-domain sent-push" "mailing-list mailing-list";align-items:flex-start;grid-gap:2rem}.domain-statistic{grid-area:statistic}.domain-settings-user{grid-area:settings-user}.domain-settings-domain{grid-area:settings-domain}.domain-sent-push{grid-area:sent-push}.domain-mailing-list{grid-area:mailing-list}

.domain-statistic{display:flex;flex-wrap:wrap;box-sizing:border-box;padding:2rem;background-color:#eaecf4;border-radius:1rem}.domain-statistic__block{box-sizing:border-box;padding:1rem;width:calc(100% / 3)}@media (max-width: 599px){.domain-statistic__block{width:100%}}.domain-statistic__title{font-size:2rem;font-weight:bold}

.domain-settings-user{display:flex;flex-wrap:wrap;box-sizing:border-box;padding:2rem;width:100%;background-color:#eaecf4;border-radius:1rem}.domain-settings-user__name-input{width:100%}.domain-settings-user__name-button{width:calc(30% - 1rem);margin-left:1rem}@media (max-width: 1023px){.domain-settings-user__name-button{margin-top:1rem;margin-left:0;width:100%}}.domain-settings-user__image-block{display:flex;align-items:center;justify-content:space-between;margin-top:1rem;width:100%}.domain-settings-user__image-wrapper{position:relative;width:calc(25% - 20px)}.domain-settings-user__image-wrapper::after{content:'';position:relative;display:block;padding-bottom:100%}.domain-settings-user__image{position:absolute;width:100%;height:100%;object-fit:cover;border-radius:50%}.domain-settings-user__image-change-button{display:flex;flex-wrap:wrap;width:70%}.domain-settings-user__bottom{display:flex;align-items:center;margin-top:1rem;width:100%}.domain-settings-user__save{width:calc(50% - 1rem);margin-right:1rem}.domain-settings-user__delete{margin-left:1rem;width:calc(50% - 1rem)}

.input{display:flex;flex-direction:column}.input__label{font-weight:bold;color:#858796}.input__item{margin-top:0.3rem;padding:0.4rem;border-radius:0.5rem;border:none}

.popup-open{overflow:hidden;position:relative}.popup{position:fixed;z-index:100;top:-1px;right:0;height:100vh;left:0;display:flex;justify-content:center;align-items:center;background-color:rgba(0,0,0,0.6)}.popup__inner{box-sizing:border-box;max-height:100vh;overflow-y:auto;padding:1.5rem;border-radius:0.6rem;max-width:80%;width:100%;background-color:#eaecf4}.popup__top{display:flex;justify-content:space-between;align-items:center;padding-bottom:1.2rem;border-bottom:1px solid #858796}.popup__title{font-size:1.4rem;font-weight:500;color:#858796}.popup__close{display:flex;justify-content:center;align-items:center;margin-left:1rem;width:1.4rem;height:1.4rem}.popup__close .icon{transition:transform .3s ease-in-out}.popup__close .icon:hover,.popup__close .icon:focus{transform:scale(1.25)}.popup__close path{fill:#858796}.popup__body{padding-top:1.4rem}

.select-image__add input{position:absolute;z-index:-1;width:0;height:0;opacity:0}.select-image__add label{display:flex;justify-content:center;box-sizing:border-box;padding:0.5rem 1rem;cursor:pointer;width:100%;font-weight:bold;color:#fff;background-color:#07923f;border-radius:3rem;transition:background-color .3s ease-in-out}.select-image__add label:hover,.select-image__add label:focus{background-color:#5a5c69}.select-image__list{margin-top:2rem;display:grid;grid-gap:2rem;grid-template-columns:repeat(4, 1fr)}.select-image__item{width:100%}.select-image__item-image-wrapper{overflow:hidden;position:relative;display:flex;width:100%}.select-image__item-image-wrapper:hover .select-image__item-image,.select-image__item-image-wrapper:focus .select-image__item-image{transform:scale(1.25)}.select-image__item-image-wrapper::after{content:'';position:relative;display:block;padding-bottom:70%}.select-image__item-image{position:absolute;width:100%;height:100%;object-fit:cover;transition:transform .3s ease-in-out}.select-image__load-more{margin-top:1rem}

.domain-settings-domain{display:flex;flex-direction:column;box-sizing:border-box;padding:2rem;background-color:#eaecf4;border-radius:1rem}.domain-settings-domain__block:not(:first-child){margin-top:2rem}.domain-settings-domain__title{color:#858796;font-weight:bold;font-size:1.2rem}.domain-settings-domain__subtitle{margin-top:0.6rem;color:#858796}.domain-settings-domain__button{margin-top:1rem;width:100%}

.domain-sent-push{display:flex;flex-direction:column;box-sizing:border-box;padding:2rem;background-color:#eaecf4;border-radius:1rem}.domain-sent-push__title{font-size:1.6rem;font-weight:bold;color:#858796}.domain-sent-push__input{margin-top:1rem}.domain-sent-push__images{display:flex;flex-wrap:wrap;margin:0 -1rem -1rem}.domain-sent-push__image-wrapper{box-sizing:border-box;padding:1rem;width:50%}@media (max-width: 767px){.domain-sent-push__image-wrapper{width:100%}}.domain-sent-push__image-checkbox{margin-bottom:1rem}.domain-sent-push__image-checkbox input{margin-left:1rem}.domain-sent-push__image{display:flex;align-items:flex-start;width:100%}.domain-sent-push__image-image-wrapper{position:relative;width:50%}.domain-sent-push__image-image-wrapper::after{content:'';position:relative;display:block;padding-bottom:100%}.domain-sent-push__image-image{position:absolute;width:100%;height:100%;object-fit:cover}.domain-sent-push__image-load{width:calc(50% - 1rem);margin-left:1rem}.domain-sent-push__button{margin-top:1rem}

.select{margin-top:1rem;padding:0.4rem;border-radius:0.5rem;border:none}

.domain-mailing-list{display:flex;flex-direction:column;box-sizing:border-box;padding:2rem;background-color:#eaecf4;border-radius:1rem}.domain-mailing-list__title-block{font-size:1.6rem;font-weight:bold;color:#858796}.domain-mailing-list__item{display:flex;padding:0.6rem;margin:0.6rem}@media (max-width: 767px){.domain-mailing-list__item{flex-wrap:wrap}}.domain-mailing-list__item:not(:last-child){border-bottom:1px solid #5a5c69}.domain-mailing-list__icon-wrapper{position:relative;width:150px}.domain-mailing-list__icon-wrapper::after{content:'';position:relative;display:block;padding-bottom:100%}.domain-mailing-list__icon{position:absolute;width:100%;height:100%;object-fit:cover}.domain-mailing-list__image-wrapper{margin-left:2rem;position:relative;width:150px}.domain-mailing-list__image-wrapper::after{content:'';position:relative;display:block;padding-bottom:100%}.domain-mailing-list__image{position:absolute;width:100%;height:100%;object-fit:cover}.domain-mailing-list__about{padding-left:1rem;flex-grow:1;width:100%}.domain-mailing-list__title{font-weight:bold}.domain-mailing-list__description{margin-top:1rem}.domain-mailing-list__link-wrapper{margin-top:1rem}.domain-mailing-list__link{transition:color .3s ease-in-out}.domain-mailing-list__link:hover,.domain-mailing-list__link:focus{color:#e74a3b}.domain-mailing-list__date{margin-top:1rem}.domain-mailing-list__statistic{display:flex;padding-left:1rem;margin:0 -1rem;width:400px}@media (max-width: 767px){.domain-mailing-list__statistic{width:100%}}.domain-mailing-list__statistic-item{box-sizing:border-box;padding:1rem}.domain-mailing-list__statistic-title{font-size:2rem;font-weight:bold}.domain-mailing-list__statistic-text{margin-top:1rem}

.domain-add-page{display:flex;flex-direction:column;align-items:center;justify-content:center;min-height:100vh}.domain-add-page__inner{width:80%;max-width:500px;display:flex;flex-direction:column;padding:2rem;border-radius:1rem;background-color:#eaecf4}.domain-add-page__image-wrapper{margin-top:1rem;position:relative;width:100%}.domain-add-page__image-wrapper::after{content:'';position:relative;display:block;padding-bottom:75%}.domain-add-page__image{position:absolute;width:100%;height:100%;object-fit:cover}.domain-add-page__image-button{margin-top:1rem}.domain-add-page__button{margin-top:1rem}

.countries-page{display:flex;flex-direction:column;height:calc(100vh - 40px)}.countries-page-body{padding-top:20px;padding-bottom:20px;flex-grow:1}

.countriesGroupsListWrapper{background-color:#e8ebf5;border-radius:10px;overflow:hidden}.countriesGroupsList{width:100%}.countriesGroupsList__Item{display:flex;justify-content:space-between;align-items:center;padding:10px 20px}.countriesGroupsList__Item:hover{background-color:#d1d3e2}@media (max-width: 767px){.countriesGroupsList__Item{flex-wrap:wrap}}.countriesGroupsList__ItemNameWrapper{display:flex;flex-wrap:wrap;margin:0 10px}@media (max-width: 767px){.countriesGroupsList__ItemNameWrapper{flex-basis:100%}}.countriesGroupsList__ItemId{margin-right:10px}.subCountriesList{flex-basis:100%}.countriesGroupsList__ItemBtnEdit{margin-left:auto;margin-right:10px}@media (max-width: 767px){.countriesGroupsList__ItemBtnEdit{margin:10px 0 0;flex-basis:100%}}@media (max-width: 767px){.countriesGroupsList__ItemBtnDelete{margin:10px 0 0;flex-basis:100%}}.subCountriesList{display:flex;flex-wrap:wrap;margin:5px -5px}.subCountriesList__Item{padding:5px;font-size:12px}.countryDelete{display:flex;justify-content:center}.countryDeleteBtnYes{margin-right:5px}.countryDeleteBtnNo{margin-left:5px}

.addCountryGroup__Header{display:flex}@media (max-width: 767px){.addCountryGroup__Header{flex-wrap:wrap}}.addCountryGroup__InputAdd{flex-grow:1}.addCountryGroup__BtnAdd{margin-left:10px}@media (max-width: 767px){.addCountryGroup__BtnAdd{flex-basis:100%;margin:10px 0 0}}.addCountryGroup__List{-webkit-column-count:4;column-count:4;margin-top:10px;background-color:#e8ebf5}.addCountryGroup__Checkbox{position:absolute;height:0;width:0;z-index:-100;opacity:0}.addCountryGroup__Checkbox:checked+.addCountryGroup__Label{color:white;background-color:#d1d3e2}.addCountryGroup__Checkbox:focus+.addCountryGroup__Label{background-color:#d1d3e2}.addCountryGroup__Label{display:block;width:100%;font-weight:bold;cursor:pointer}.addCountryGroup__Label:hover{background-color:#d1d3e2}

.countriesList{padding:10px 0;margin-top:20px;background-color:#e8ebf5;border-radius:10px;overflow:hidden}@media (min-width: 1024px){.countriesList{-webkit-column-count:3;column-count:3}}@media (min-width: 768px) and (max-width: 1023px){.countriesList{-webkit-column-count:2;column-count:2}}.countriesList__Item{display:flex;justify-content:flex-start;padding:5px 20px}.countriesList__ItemShortName{margin:0 10px 0 0;font-weight:bold}

.main--stub{box-sizing:#eaecf4;display:flex;align-items:center;justify-content:center}.main--stub .page-stub,.main--stub .page-stub button{font-size:2rem;font-weight:bold;color:#858796;cursor:default;z-index:-1}

